.MainAnimateLogoContainer
  display: flex
  justify-content: center
  align-items: center
  position: relative
  width: 100%
  height: 100%

  .InnerAnimateLogoContainer
    display: block
    position: relative
    width: 100%
    height: 100%
    max-width: 532px
    max-height: 532px

    .inner_company_logo
      position: absolute
      z-index: 100
      left: 0
      top: 0
      width: 100%
      height: 100%

    .inner_cogs_company_logo
      position: absolute
      z-index: 100
      left: 0
      top: 0
      width: 100%
      height: 100%
      animation-name: rotate
      animation-duration: 40s
      animation-iteration-count: infinite
      animation-timing-function: linear

    .outside_cogs_company_logo
      position: absolute
      z-index: 100
      left: 0
      top: 0
      width: 100%
      height: 100%
      animation-name: rotate
      animation-duration: 50s
      animation-iteration-count: infinite
      animation-timing-function: linear
      animation-direction: reverse

@keyframes rotate
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
