.MainButtonsContainer
  display: block
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  z-index: 1000

  .Around
    justify-content: space-between

  .InnerRowContainer
    display: flex
    width: 100%
    height: 20%

    .ButtonContainer
      position: relative
      display: flex
      justify-content: center
      align-items: center
      height: 100%
      width: 20%
      transition: opacity 4s
      opacity: 1

      .Button
        display: flex
        justify-content: center
        align-items: center
        position: relative
        width: 95%
        height: 95%
        margin: 0
        padding: 0
        border-radius: 50%
        outline: none
        border: none
        background: none

        .InnerBlockButton
          display: flex
          justify-content: center
          align-items: center
          position: relative
          z-index: 1005
          overflow: hidden
          width: 75%
          height: 75%
          margin: 0
          padding: 7%
          border-radius: 50%
          color: blue
          font-size: 1.8vmin
          font-weight: bold

        &:hover:after
          animation-name: rotate
          animation-duration: 15s
          animation-iteration-count: infinite
          animation-timing-function: linear

        &:hover:before
          animation-name: pulse
          animation-duration: 1s
          animation-iteration-count: infinite
          animation-timing-function: linear


        &:before
          position: absolute
          z-index: 1000
          content: ''
          left: 50%
          top: 50%
          transform: translate(-50%,-50%)
          margin: 0
          padding: 0
          width: 0
          height: 0
          background: rgba(61, 51, 255, 0.61)
          border-radius: 50%
          border: none
          filter: blur(5px)

        &:after
          position: absolute
          z-index: 1000
          content: ''
          left: 0
          top: 0
          margin: 0
          padding: 0
          width: 100%
          height: 100%
          background-image: url("/images/outside_cogs_circle.png")
          background-size: cover
    .hidden
      opacity: 0

@keyframes rotate
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes pulse
  from
    width: 0
    height: 0
    opacity: 1
  to
    width: 100%
    height: 100%
    opacity: 0.1
