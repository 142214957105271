.MainContainer
  position: absolute
  z-index: 1
  width: 100vmin
  height: 100vmin
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  overflow: hidden

  .InnerContainerLogo
    position: absolute
    z-index: 2
    width: 60%
    height: 60%
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    transition: top 2s

  .BlockContainer
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 0
    z-index: 500

  .hidden
    top: -50%
